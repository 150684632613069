import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { ListItem } from "@material-tailwind/react";
import { formatDate } from '../../utils/utilities';
import { modelContext } from '../../Provider';

const MobileNotifications = (props) => {
    
    const [notificaitons, setNotificaitons] = useState([])
    const [model, setModel] = useContext(modelContext);

    useEffect(() => {
        const URL = process.env.REACT_APP_API_URL + "/auth/notifications"
        const body = {
            "action": "fetch"
        }

        axios.post(URL, body)
            .then((resp) => {
                const data = resp.data
                if(data) {
                    setNotificaitons(data)
                }
            })
            .catch((e) =>  console.log(e))

    }, [])

    return <>
        <div className="lg:mx-auto" >
                <div className="mx-auto w-full">
                {notificaitons && notificaitons.length > 0 ? 
                            notificaitons.map(item => <ListItem className="flex justify-between space-y-8 border-b h-24 px-2"
                                        onClick={() => setModel({show: !model.show, type: 'content', title: item.subject, msg: item.msg })}>
                                    <div className="flex flex-col">
                                        <div className="text-black text-md flex font-semibold my-1">
                                            <div>{item.subject}</div> 
                                        </div>
                                        <div className="text-gray-600 text-sm font-extralight flex my-1">
                                            {item.msg.slice(0, 50) + "..."}
                                        </div>
                                    </div>
                                    <div className="flex-1 text-end text-xs">{formatDate(item.updatedAt)} {new Date(item.updatedAt).toLocaleTimeString('en-US')}</div>
                                </ListItem>)
                            : 
                            <div className="h-full mt-20 flex justify-center items-center">
                                No new notificaitons
                            </div>
                        }
                </div>
        </div>
    </>
}

export default MobileNotifications;